<template>
  <div class="dashboard">
    <Navigation />
    
    <div class="content">
      <div class="welcome-section">
        <h1>Welcome to <span class="highlight">Prec8</span></h1>
        <p class="subtitle">Recognize and celebrate your team's achievements</p>
      </div>

      <div class="dashboard-cards">
        <div class="card">
          <div class="card-icon">🏆</div>
          <h3>Your Points</h3>
          <div class="points">{{ userPoints }}</div>
        </div>
        <div class="card">
          <div class="card-icon">📊</div>
          <h3>Given</h3>
          <div class="rank">{{ pointsGiven || '-' }}</div>
        </div>
        <div class="card">
          <div class="card-icon">🎁</div>
          <h3>Redeemable Points</h3>
          <div class="points-to-give">{{ redeemablePoints }}</div>
        </div>
      </div>

      <div class="decoration">
        <div class="floating-card card-1">
          <span class="emoji">🌟</span>
          <p>Keep up the great work!</p>
        </div>
        <div class="floating-card card-2">
          <span class="emoji">🚀</span>
          <p>Ready to recognize others?</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import Navigation from './components/Navigation.vue'

const userPoints = ref(0)
const pointsGiven = ref(0)
const redeemablePoints = ref(0)

onMounted(async () => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/my-stats`, {
      credentials: 'include',
      headers: {
        'Accept': 'application/json'
      }
    })
    const data = await response.json()
    userPoints.value = data.points || 0
    pointsGiven.value = data.pointsGiven || 0
    redeemablePoints.value = data.redeemablePoints || 0
  } catch (error) {
    console.error('Error fetching user stats:', error)
  }
})
</script>

<style scoped>
.dashboard {
  min-height: 100vh;
  background: linear-gradient(135deg, #f6f9fc 0%, #eef2f7 100%);
  font-family: 'Inter', system-ui, -apple-system, sans-serif;
}

.content {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.welcome-section {
  text-align: center;
  margin-bottom: 3rem;
}

h1 {
  font-size: 2.5rem;
  font-weight: 800;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.highlight {
  background: linear-gradient(120deg, #FF6B6B 0%, #FF8E53 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.subtitle {
  font-size: 1.25rem;
  color: #666;
}

.dashboard-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.card {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
}

.card-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

h3 {
  color: #666;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.points, .rank, .points-to-give {
  font-size: 2rem;
  font-weight: 700;
  color: #2c3e50;
}

.decoration {
  position: relative;
  height: 200px;
}

.floating-card {
  position: absolute;
  background: white;
  padding: 1rem;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
}

.card-1 {
  top: 20%;
  right: 10%;
  transform: rotate(10deg);
  animation: float 6s ease-in-out infinite;
}

.card-2 {
  bottom: 20%;
  left: 10%;
  transform: rotate(-10deg);
  animation: float 6s ease-in-out infinite;
  animation-delay: -3s;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) rotate(10deg);
  }
  50% {
    transform: translateY(-10px) rotate(10deg);
  }
}

@media (max-width: 768px) {
  .content {
    padding: 1rem;
  }
  
  h1 {
    font-size: 2rem;
  }
  
  .subtitle {
    font-size: 1rem;
  }
  
  .decoration {
    display: none;
  }
}
</style>
