<template>
  <div class="container mx-auto px-4 py-8">
    <Navigation />
    
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-8">
      <!-- Monthly Leaderboard -->
      <div class="bg-white rounded-lg shadow-lg p-6">
        <h2 class="text-2xl font-bold mb-4">Monthly Leaders</h2>
        <div v-if="loading.monthly" class="text-center py-8">
          <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
        </div>
        <div v-else-if="error.monthly" class="text-red-600 text-center py-8">
          {{ error.monthly }}
        </div>
        <div v-else class="overflow-x-auto">
          <LeaderboardTable :entries="leaderboards.monthly" />
        </div>
      </div>

      <!-- Yearly Leaderboard -->
      <div class="bg-white rounded-lg shadow-lg p-6">
        <h2 class="text-2xl font-bold mb-4">Yearly Leaders</h2>
        <div v-if="loading.yearly" class="text-center py-8">
          <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
        </div>
        <div v-else-if="error.yearly" class="text-red-600 text-center py-8">
          {{ error.yearly }}
        </div>
        <div v-else class="overflow-x-auto">
          <LeaderboardTable :entries="leaderboards.yearly" />
        </div>
      </div>

      <!-- All Time Leaderboard -->
      <div class="bg-white rounded-lg shadow-lg p-6">
        <h2 class="text-2xl font-bold mb-4">All Time Leaders</h2>
        <div v-if="loading.allTime" class="text-center py-8">
          <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
        </div>
        <div v-else-if="error.allTime" class="text-red-600 text-center py-8">
          {{ error.allTime }}
        </div>
        <div v-else class="overflow-x-auto">
          <LeaderboardTable :entries="leaderboards.allTime" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import Navigation from './components/Navigation.vue'
import LeaderboardTable from './components/LeaderboardTable.vue'

interface LeaderboardEntry {
  rank: number
  name: string
  points: number
  slackId: string
}

interface LeaderboardState {
  monthly: LeaderboardEntry[]
  yearly: LeaderboardEntry[]
  allTime: LeaderboardEntry[]
}

interface LoadingState {
  monthly: boolean
  yearly: boolean
  allTime: boolean
}

interface ErrorState {
  monthly: string
  yearly: string
  allTime: string
}

const leaderboards = ref<LeaderboardState>({
  monthly: [],
  yearly: [],
  allTime: []
})

const loading = ref<LoadingState>({
  monthly: true,
  yearly: true,
  allTime: true
})

const error = ref<ErrorState>({
  monthly: '',
  yearly: '',
  allTime: ''
})

async function fetchLeaderboard(period: string): Promise<LeaderboardEntry[]> {
  const response = await fetch(
    `${process.env.VUE_APP_API_BASE_URL}/leaderboard?period=${period}`,
    { credentials: 'include' }
  )
  
  if (!response.ok) {
    throw new Error('Failed to fetch leaderboard')
  }
  
  return response.json()
}

async function fetchAllLeaderboards() {
  // Fetch monthly leaderboard
  try {
    loading.value.monthly = true
    error.value.monthly = ''
    leaderboards.value.monthly = await fetchLeaderboard('monthly')
  } catch (e) {
    error.value.monthly = 'Failed to load monthly leaderboard'
    console.error('Error fetching monthly leaderboard:', e)
  } finally {
    loading.value.monthly = false
  }

  // Fetch yearly leaderboard
  try {
    loading.value.yearly = true
    error.value.yearly = ''
    leaderboards.value.yearly = await fetchLeaderboard('yearly')
  } catch (e) {
    error.value.yearly = 'Failed to load yearly leaderboard'
    console.error('Error fetching yearly leaderboard:', e)
  } finally {
    loading.value.yearly = false
  }

  // Fetch all-time leaderboard
  try {
    loading.value.allTime = true
    error.value.allTime = ''
    leaderboards.value.allTime = await fetchLeaderboard('all_time')
  } catch (e) {
    error.value.allTime = 'Failed to load all-time leaderboard'
    console.error('Error fetching all-time leaderboard:', e)
  } finally {
    loading.value.allTime = false
  }
}

onMounted(fetchAllLeaderboards)
</script>

<style scoped>
.leaderboard {
  min-height: 100vh;
  background: linear-gradient(135deg, #f6f9fc 0%, #eef2f7 100%);
  font-family: 'Inter', system-ui, -apple-system, sans-serif;
}

.content {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  font-size: 2.5rem;
  font-weight: 800;
  margin-bottom: 2rem;
  color: #1a1a1a;
  text-align: center;
}

.leaderboard-table {
  background: white;
  border-radius: 16px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.table-header {
  display: grid;
  grid-template-columns: 100px 1fr 120px;
  padding: 1rem;
  background: #f8f9fa;
  font-weight: 600;
  color: #666;
}

.table-row {
  display: grid;
  grid-template-columns: 100px 1fr 120px;
  padding: 1rem;
  border-top: 1px solid #eee;
  transition: background-color 0.2s ease;
}

.table-row:hover {
  background-color: #f8f9fa;
}

.rank {
  text-align: center;
  color: #666;
  font-weight: 500;
}

.name {
  color: #2c3e50;
  font-weight: 500;
}

.points {
  text-align: right;
  color: #42b983;
  font-weight: 600;
}

@media (max-width: 768px) {
  .content {
    padding: 1rem;
  }
  
  h1 {
    font-size: 2rem;
  }
  
  .table-header, .table-row {
    grid-template-columns: 80px 1fr 100px;
    font-size: 0.9rem;
  }
}
</style>
