import { createApp } from "vue";
import App from "./App.vue";
import Home from "./Home.vue";
import InstallSuccess from "./InstallSuccess.vue";
import SignIn from "./SignIn.vue";
import Leaderboard from "./Leaderboard.vue";
import Dashboard from "./Dashboard.vue";
import RewardsStore from "./RewardsStore.vue";
import {
  createWebHistory,
  createRouter,
  createWebHashHistory,
} from "vue-router";

const routes = [
  { path: "/", component: Home },
  { path: "/install-success", component: InstallSuccess },
  { path: "/sign-in", component: SignIn },
  { path: "/leaderboard", component: Leaderboard },
  { path: "/signin-success", redirect: "/dashboard"  },
  { path: "/dashboard", component: Dashboard },
  { path: "/rewards", component: RewardsStore },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// Navigation guard to check authentication
router.beforeEach(async (to, from, next) => {
  // Routes that don't require authentication
  const publicRoutes = ['/', '/sign-in', '/install-success'];

  if (publicRoutes.includes(to.path)) {
    next();
    return;
  }

  // Add debug logging
  console.log('Checking authentication for:', to.path);
  
  try {
    // Use the ngrok URL for auth check
    const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/check-auth`, {
      credentials: 'include',
      headers: {
          'Accept': 'application/json'
      }
    }); 
    const data = await response.json();
    
    // Add debug logging
    console.log('Auth check response:', data);

    if (data.isAuthenticated) {
      next();
    } else {
      console.log('Not authenticated, redirecting to sign-in');
      next('/sign-in');
    }
  } catch (error) {
    console.error('Auth check failed:', error);
    next('/sign-in');
  }
});

const app = createApp(App);
app.use(router);
app.mount("#app");
