<template>
  <div class="home">
    <!-- Hero Section -->
    <section class="hero">
      <div class="hero-content">
        <div class="hero-text">
          <h1>Celebrate<br/>Your Team's<br/><span class="highlight">Victories</span></h1>
          <p class="hero-subtitle">Simple recognition that brings teams together</p>
          <div class="cta-buttons">
            <a :href="addToSlackUrl" class="slack-button">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.8 122.8" class="slack-icon">
                <path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"/>
                <path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"/>
                <path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"/>
                <path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"/>
              </svg>
              Add to Slack
            </a>
            <button @click="signIn" class="sign-in-button">Sign In</button>
          </div>
        </div>
        <div class="hero-image">
          <div class="floating-card card-1">
            <span class="emoji">🎉</span>
            <p>Great job on the release!</p>
          </div>
          <div class="floating-card card-2">
            <span class="emoji">⭐️</span>
            <p>Thanks for helping out!</p>
          </div>
        </div>
      </div>
      <div class="hero-shape"></div>
    </section>

    <!-- Features Section -->
    <section class="features">
      <div class="section-content">
        <h2>Recognition Made <span class="highlight">Simple</span></h2>
        <div class="features-grid">
          <div class="feature-card">
            <div class="feature-icon">🎯</div>
            <h3>Give Points</h3>
            <p>Recognize teammates with a simple Slack message</p>
          </div>
          <div class="feature-card">
            <div class="feature-icon">🏆</div>
            <h3>Track Progress</h3>
            <p>Watch your team's appreciation grow</p>
          </div>
          <div class="feature-card">
            <div class="feature-icon">💪</div>
            <h3>Build Culture</h3>
            <p>Create a positive team environment</p>
          </div>
        </div>
      </div>
      <div class="features-shape"></div>
    </section>

    <!-- How It Works -->
    <section class="how-it-works">
      <div class="section-content">
        <h2>Start in <span class="highlight">3 Steps</span></h2>
        <div class="steps">
          <div class="step">
            <span class="step-number">1</span>
            <h3>Add to Slack</h3>
            <p>Install Prec8 in your workspace</p>
          </div>
          <div class="step">
            <span class="step-number">2</span>
            <h3>Give Points</h3>
            <p>Recognize teammates with points</p>
          </div>
          <div class="step">
            <span class="step-number">3</span>
            <h3>Track Growth</h3>
            <p>Watch team appreciation flourish</p>
          </div>
        </div>
      </div>
    </section>

    <!-- Footer -->
    <footer class="footer">
      <p>&copy; {{ currentYear }} Prec8. All rights reserved.</p>
    </footer>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class Home extends Vue {
  figletText = "";
  addToSlackUrl = "";
  currentYear = new Date().getFullYear();

  signIn() {
    this.$router.push("/sign-in");
  }

  mounted() {
    let installRedirectUri = process.env.VUE_APP_API_BASE_URL + "/install-callback" || "https://chat.prec8.com/install-callback";
    let slackClientId = process.env.VUE_APP_SLACK_CLIENT_ID || "5302144459057.7245506041362";
    this.addToSlackUrl = "https://slack.com/oauth/v2/authorize?client_id=" + slackClientId + "&scope=channels:history,channels:join,channels:read,chat:write,commands,groups:history,groups:read,im:history,im:read,im:write,links:read,mpim:history,mpim:read,reactions:read,team:read,users:read,users:read.email,users:write,app_mentions:read,emoji:read&user_scope=channels:read,channels:write,groups:read,groups:write,users:read&redirect_uri=" + installRedirectUri;
  }
}
</script>

<style lang="scss">
.home {
  font-family: 'Inter', system-ui, -apple-system, sans-serif;
  color: #1a1a1a;
  line-height: 1.5;
  overflow-x: hidden;
}

.highlight {
  background: linear-gradient(120deg, #FF6B6B 0%, #FF8E53 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.hero {
  position: relative;
  background: #ffffff;
  padding: 8rem 2rem;
  overflow: hidden;

  .hero-content {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    position: relative;
    z-index: 2;
  }

  .hero-text {
    padding-top: 2rem;
  }

  h1 {
    font-size: 4.5rem;
    font-weight: 800;
    line-height: 1.1;
    margin-bottom: 1.5rem;
  }

  .hero-subtitle {
    font-size: 1.5rem;
    color: #666;
    margin-bottom: 2.5rem;
  }

  .hero-image {
    position: relative;
    height: 400px;
  }

  .floating-card {
    position: absolute;
    background: white;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 20px 40px rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    gap: 1rem;
    
    .emoji {
      font-size: 2rem;
    }

    p {
      margin: 0;
      font-weight: 500;
    }
  }

  .card-1 {
    top: 20%;
    right: 10%;
    animation: float 6s ease-in-out infinite;
  }

  .card-2 {
    bottom: 20%;
    left: 10%;
    animation: float 6s ease-in-out infinite;
    animation-delay: -3s;
  }

  .hero-shape {
    position: absolute;
    bottom: -100px;
    left: 0;
    right: 0;
    height: 200px;
    background: #f8fafc;
    transform: skewY(-6deg);
    z-index: 1;
  }
}

.features {
  position: relative;
  background: #f8fafc;
  padding: 8rem 2rem;

  .section-content {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }

  h2 {
    text-align: center;
    font-size: 3.5rem;
    font-weight: 800;
    margin-bottom: 4rem;
  }

  .features-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  .feature-card {
    background: white;
    padding: 2rem;
    border-radius: 16px;
    text-align: center;
    box-shadow: 0 10px 30px rgba(0,0,0,0.05);
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-10px);
    }

    .feature-icon {
      font-size: 3rem;
      margin-bottom: 1.5rem;
    }

    h3 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }

    p {
      color: #666;
    }
  }

  .features-shape {
    position: absolute;
    bottom: -100px;
    left: 0;
    right: 0;
    height: 200px;
    background: white;
    transform: skewY(6deg);
    z-index: 1;
  }
}

.how-it-works {
  background: white;
  padding: 8rem 2rem;

  .section-content {
    max-width: 1200px;
    margin: 0 auto;
  }

  h2 {
    text-align: center;
    font-size: 3.5rem;
    font-weight: 800;
    margin-bottom: 4rem;
  }

  .steps {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  .step {
    text-align: center;
    padding: 2rem;

    .step-number {
      display: inline-block;
      width: 60px;
      height: 60px;
      line-height: 60px;
      border-radius: 30px;
      background: linear-gradient(120deg, #FF6B6B 0%, #FF8E53 100%);
      color: white;
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 1.5rem;
    }

    h3 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }

    p {
      color: #666;
    }
  }
}

.cta-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  
  .slack-button, .sign-in-button {
    display: inline-flex;
    align-items: center;
    padding: 1rem 2rem;
    border-radius: 12px;
    font-weight: 600;
    font-size: 1.125rem;
    text-decoration: none;
    transition: transform 0.2s ease;
  }

  .slack-button {
    background: #4A154B;
    color: white;
    border: none;
    box-shadow: 0 10px 20px rgba(74, 21, 75, 0.2);
    
    &:hover {
      transform: translateY(-2px);
    }

    .slack-icon {
      width: 24px;
      height: 24px;
      margin-right: 0.75rem;
    }
  }

  .sign-in-button {
    background: white;
    color: #1a1a1a;
    border: 2px solid #e0e0e0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    
    &:hover {
      transform: translateY(-2px);
      border-color: #1a1a1a;
    }
  }
}

.footer {
  background: #f8fafc;
  padding: 2rem;
  text-align: center;
  color: #666;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@media (max-width: 1024px) {
  .hero {
    .hero-content {
      grid-template-columns: 1fr;
      text-align: center;
      gap: 2rem;
    }

    h1 {
      font-size: 3.5rem;
    }

    .hero-image {
      height: 300px;
    }
  }

  .features {
    .features-grid {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  }

  .how-it-works {
    .steps {
      grid-template-columns: 1fr;
      max-width: 500px;
      margin: 0 auto;
    }
  }
}

@media (max-width: 768px) {
  .hero {
    padding: 4rem 1rem;

    h1 {
      font-size: 2.8rem;
    }

    .hero-subtitle {
      font-size: 1.25rem;
    }
  }

  .features, .how-it-works {
    padding: 4rem 1rem;

    h2 {
      font-size: 2.5rem;
    }
  }
}
</style>
