<template>
  <div class="container mx-auto px-4 py-8">
    <Navigation />
    
    <!-- User Points Section -->
    <div class="bg-white rounded-lg shadow-lg p-6 mb-8">
      <div class="flex justify-between items-center">
        <h2 class="text-2xl font-bold">Your Points</h2>
        <div class="text-xl font-semibold text-blue-600">{{ userPoints }} points available</div>
      </div>
    </div>

    <!-- Store Section -->
    <div class="bg-white rounded-lg shadow-lg p-6">
      <div class="flex justify-between items-center mb-6">
        <h2 class="text-2xl font-bold">Rewards Store</h2>
        <div v-if="isAdmin" class="flex gap-2">
          <button 
            @click="showAddItemModal = true"
            class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md text-sm"
          >
            Add Item
          </button>
        </div>
      </div>

      <!-- Loading State -->
      <div v-if="loading" class="text-center py-8">
        <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
      </div>

      <!-- Error State -->
      <div v-else-if="error" class="text-red-600 text-center py-8">
        {{ error }}
      </div>

      <!-- Store Items Grid -->
      <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div v-for="item in storeItems" :key="item.id" class="bg-gray-50 rounded-lg p-4">
          <img 
            v-if="item.imageUrl" 
            :src="item.imageUrl" 
            :alt="item.name"
            class="w-full h-48 object-cover rounded-md mb-4"
          />
          <div v-else class="w-full h-48 bg-gray-200 rounded-md mb-4 flex items-center justify-center">
            <span class="text-gray-400">No image</span>
          </div>
          
          <h3 class="text-lg font-semibold mb-2">{{ item.name }}</h3>
          <p class="text-gray-600 mb-4">{{ item.description }}</p>
          <div class="flex justify-between items-center">
            <span class="text-blue-600 font-bold">{{ item.points }} points</span>
            <div class="flex gap-2">
              <button 
                v-if="isAdmin"
                @click="editItem(item)"
                class="text-gray-600 hover:text-gray-800 px-2 py-1"
              >
                Edit
              </button>
              <button 
                @click="redeemItem(item)"
                :disabled="userPoints < item.points || item.quantity === 0"
                class="bg-green-500 hover:bg-green-600 disabled:bg-gray-400 text-white px-4 py-2 rounded-md text-sm"
              >
                Redeem
              </button>
            </div>
          </div>
          <div class="mt-2 text-sm text-gray-500">
            {{ item.quantity }} remaining
          </div>
        </div>
      </div>

      <!-- Add/Edit Item Modal -->
      <div v-if="showAddItemModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
        <div class="bg-white rounded-lg p-6 max-w-md w-full">
          <h3 class="text-xl font-bold mb-4">{{ editingItem ? 'Edit' : 'Add' }} Item</h3>
          <form @submit.prevent="saveItem">
            <div class="mb-4">
              <label class="block text-sm font-medium text-gray-700 mb-1">Name</label>
              <input 
                v-model="itemForm.name"
                type="text"
                required
                class="w-full border border-gray-300 rounded-md px-3 py-2"
              />
            </div>
            <div class="mb-4">
              <label class="block text-sm font-medium text-gray-700 mb-1">Description</label>
              <textarea 
                v-model="itemForm.description"
                required
                class="w-full border border-gray-300 rounded-md px-3 py-2"
              ></textarea>
            </div>
            <div class="mb-4">
              <label class="block text-sm font-medium text-gray-700 mb-1">Points</label>
              <input 
                v-model.number="itemForm.points"
                type="number"
                required
                min="1"
                class="w-full border border-gray-300 rounded-md px-3 py-2"
              />
            </div>
            <div class="mb-4">
              <label class="block text-sm font-medium text-gray-700 mb-1">Quantity</label>
              <input 
                v-model.number="itemForm.quantity"
                type="number"
                required
                min="0"
                class="w-full border border-gray-300 rounded-md px-3 py-2"
              />
            </div>
            <div class="mb-4">
              <label class="block text-sm font-medium text-gray-700 mb-1">Image URL</label>
              <input 
                v-model="itemForm.imageUrl"
                type="url"
                class="w-full border border-gray-300 rounded-md px-3 py-2"
              />
            </div>
            <div class="flex justify-end gap-2">
              <button 
                type="button"
                @click="showAddItemModal = false"
                class="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button 
                type="submit"
                class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md text-sm"
              >
                {{ editingItem ? 'Save' : 'Add' }}
              </button>
            </div>
          </form>
        </div>
      </div>

      <!-- Confirmation Modal -->
      <div v-if="showConfirmModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
        <div class="bg-white rounded-lg p-6 max-w-md w-full">
          <h3 class="text-xl font-bold mb-4">Confirm Redemption</h3>
          <p class="mb-4">Are you sure you want to redeem {{ selectedItem?.name }} for {{ selectedItem?.points }} points?</p>
          <div class="flex justify-end gap-2">
            <button 
              @click="showConfirmModal = false"
              class="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
            >
              Cancel
            </button>
            <button 
              @click="confirmRedeem"
              class="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md text-sm"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import Navigation from './components/Navigation.vue'

interface StoreItem {
  id: string
  name: string
  description: string
  points: number
  quantity: number
  imageUrl?: string
}

const storeItems = ref<StoreItem[]>([])
const loading = ref(true)
const error = ref('')
const showAddItemModal = ref(false)
const showConfirmModal = ref(false)
const selectedItem = ref<StoreItem | null>(null)
const editingItem = ref<StoreItem | null>(null)
const userPoints = ref(0)
const isAdmin = ref(false)

const itemForm = ref({
  name: '',
  description: '',
  points: 0,
  quantity: 0,
  imageUrl: ''
})

onMounted(async () => {
  await Promise.all([
    fetchStoreItems(),
    fetchUserPoints(),
    checkAdminStatus()
  ])
})

async function fetchStoreItems() {
  loading.value = true
  error.value = ''
  
  try {
    const response = await fetch(
      `${process.env.VUE_APP_API_BASE_URL}/store/items`,
      { credentials: 'include' }
    )
    
    if (!response.ok) {
      throw new Error('Failed to fetch store items')
    }
    
    storeItems.value = await response.json()
  } catch (e) {
    error.value = 'Failed to load store items. Please try again later.'
    console.error('Error fetching store items:', e)
  } finally {
    loading.value = false
  }
}

async function fetchUserPoints() {
  try {
    const response = await fetch(
      `${process.env.VUE_APP_API_BASE_URL}/my-stats`,
      { credentials: 'include' }
    )
    
    if (!response.ok) {
      throw new Error('Failed to fetch user points')
    }
    
    const data = await response.json()
    userPoints.value = data.redeemablePoints
  } catch (e) {
    console.error('Error fetching user points:', e)
  }
}

async function checkAdminStatus() {
  try {
    const response = await fetch(
      `${process.env.VUE_APP_API_BASE_URL}/user/is-admin`,
      { credentials: 'include' }
    )
    
    if (!response.ok) {
      throw new Error('Failed to check admin status')
    }
    
    const data = await response.json()
    isAdmin.value = data.isAdmin
  } catch (e) {
    console.error('Error checking admin status:', e)
    isAdmin.value = false
  }
}

function editItem(item: StoreItem) {
  editingItem.value = item
  itemForm.value = { ...item }
  showAddItemModal.value = true
}

async function saveItem() {
  try {
    const endpoint = editingItem.value
      ? `/store/items/${editingItem.value.id}`
      : '/store/items'
    
    const response = await fetch(
      `${process.env.VUE_APP_API_BASE_URL}${endpoint}`,
      {
        method: editingItem.value ? 'PUT' : 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(itemForm.value)
      }
    )
    
    if (!response.ok) {
      throw new Error('Failed to save item')
    }
    
    await fetchStoreItems()
    showAddItemModal.value = false
    editingItem.value = null
    itemForm.value = {
      name: '',
      description: '',
      points: 0,
      quantity: 0,
      imageUrl: ''
    }
  } catch (e) {
    console.error('Error saving item:', e)
    alert('Failed to save item. Please try again.')
  }
}

function redeemItem(item: StoreItem) {
  selectedItem.value = item
  showConfirmModal.value = true
}

async function confirmRedeem() {
  if (!selectedItem.value) return
  
  try {
    const response = await fetch(
      `${process.env.VUE_APP_API_BASE_URL}/store/redeem/${selectedItem.value.id}`,
      {
        method: 'POST',
        credentials: 'include'
      }
    )
    
    if (!response.ok) {
      throw new Error('Failed to redeem item')
    }
    
    await Promise.all([
      fetchStoreItems(),
      fetchUserPoints()
    ])
    showConfirmModal.value = false
    selectedItem.value = null
  } catch (e) {
    console.error('Error redeeming item:', e)
    alert('Failed to redeem item. Please try again.')
  }
}
</script>
