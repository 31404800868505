<template>
  <div class="sign-in">
    <div class="sign-in-container">
      <div class="back-button">
        <button @click="goHome" class="subtle-button">
          ← Back to Home
        </button>
      </div>
      
      <div class="content">
        <h1>Welcome Back to <span class="highlight">Prec8</span></h1>
        <p class="subtitle">Sign in to manage your team recognition</p>

        <div class="auth-options">
          <a :href="signInToSlackUrl" class="slack-button">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.8 122.8" class="slack-icon">
              <path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"/>
              <path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"/>
              <path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"/>
              <path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"/>
            </svg>
            Continue with Slack
          </a>
        </div>

        <div class="info-text">
          <p>Sign in securely using your Slack workspace credentials</p>
        </div>
      </div>

      <div class="decoration">
        <div class="floating-card card-1">
          <span class="emoji">🌟</span>
          <p>Welcome back!</p>
        </div>
        <div class="floating-card card-2">
          <span class="emoji">👋</span>
          <p>Good to see you again</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class SignIn extends Vue {
  figletText = "";
  signInToSlackUrl = "";

  goHome() {
    this.$router.push("/");
  }

  mounted() {
    const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
    const slackClientId = process.env.VUE_APP_SLACK_CLIENT_ID;

    if (!apiBaseUrl || !slackClientId) {
      console.error('Missing required environment variables:', {
        apiBaseUrl: !!apiBaseUrl,
        slackClientId: !!slackClientId
      });
      return;
    }

    const signInRedirectUri = `${apiBaseUrl}/signin-callback`;
    this.signInToSlackUrl = `https://slack.com/openid/connect/authorize?client_id=${slackClientId}&response_type=code&scope=openid,email,profile&redirect_uri=${signInRedirectUri}`;
  }
}
</script>

<style lang="scss">
.sign-in {
  min-height: 100vh;
  background: linear-gradient(135deg, #f6f9fc 0%, #eef2f7 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', system-ui, -apple-system, sans-serif;
  padding: 2rem;
}

.sign-in-container {
  background: white;
  border-radius: 24px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 480px;
  position: relative;
  overflow: hidden;
  padding: 3rem;
}

.back-button {
  position: absolute;
  top: 2rem;
  left: 2rem;
}

.subtle-button {
  background: none;
  border: none;
  color: #666;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  transition: all 0.2s ease;

  &:hover {
    background: #f6f9fc;
    color: #1a1a1a;
  }
}

.content {
  text-align: center;
  position: relative;
  z-index: 2;
}

h1 {
  font-size: 2.5rem;
  font-weight: 800;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.highlight {
  background: linear-gradient(120deg, #FF6B6B 0%, #FF8E53 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.subtitle {
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 2.5rem;
}

.auth-options {
  margin-bottom: 2rem;
}

.slack-button {
  display: inline-flex;
  align-items: center;
  padding: 1rem 2rem;
  background: #4A154B;
  color: white;
  border-radius: 12px;
  font-weight: 600;
  text-decoration: none;
  transition: transform 0.2s ease;
  box-shadow: 0 10px 20px rgba(74, 21, 75, 0.2);

  &:hover {
    transform: translateY(-2px);
  }

  .slack-icon {
    width: 24px;
    height: 24px;
    margin-right: 0.75rem;
  }
}

.info-text {
  color: #666;
  font-size: 0.875rem;
  margin-top: 2rem;
}

.decoration {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

.floating-card {
  position: absolute;
  background: #f8fafc;
  padding: 1rem;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  
  .emoji {
    font-size: 1.5rem;
  }

  p {
    margin: 0;
    font-weight: 500;
    font-size: 0.875rem;
    color: #1a1a1a;
  }
}

.card-1 {
  top: 10%;
  right: -20px;
  transform: rotate(10deg);
  animation: float 6s ease-in-out infinite;
}

.card-2 {
  bottom: 10%;
  left: -20px;
  transform: rotate(-10deg);
  animation: float 6s ease-in-out infinite;
  animation-delay: -3s;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) rotate(10deg);
  }
  50% {
    transform: translateY(-10px) rotate(10deg);
  }
}

@media (max-width: 768px) {
  .sign-in-container {
    padding: 2rem;
    margin: 1rem;
  }

  h1 {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1.125rem;
  }

  .floating-card {
    display: none;
  }
}
</style>
